import {createI18n, LocaleMessageDictionary, VueMessageType} from 'vue-i18n'
import AvailableLocale from "@/types/AvailableLocale";
import {
    DateTimeFormats,
    NumberFormats as IntlNumberFormats,
    NumberFormat as IntlNumberFormat,
    DateTimeFormat
} from "@intlify/core-base";
import 'vite/types/importMeta.d';

function loadLocaleMessages() {
    const locales = import.meta.glob('./Messages/*.json', { import: 'default', eager: true })
    const messages: { [key: string]: LocaleMessageDictionary<VueMessageType> } = {}
    Object.keys(locales).forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1 && matched[1] !== 'frontend') {
            const locale = matched[1].replace('_', '-')
            messages[locale] = <LocaleMessageDictionary<VueMessageType>>locales[key]
        }
    })
    return messages
}

function loadLocaleDateTimeFormats() {
    const locales = import.meta.glob('./DateTime/*.json', { import: 'default', eager: true })
    const messages: DateTimeFormats = {}
    Object.keys(locales).forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1].replace('_', '-')
            messages[locale] = <DateTimeFormat>locales[key]
        }
    })
    return messages
}

function loadLocaleNumberFormats() {
    const locales = import.meta.glob('./NumberFormat/*.json', { import: 'default', eager: true })
    const messages: IntlNumberFormats = {}
    Object.keys(locales).forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1].replace('_', '-')
            messages[locale] = <IntlNumberFormat>locales[key]
        }
    })
    return messages
}

interface Navigator {
    userLanguage?: string;
    browserLanguage?: string;
    languages: readonly string[];
    language: string;
}

const navigator: Navigator = window.navigator;

let appLocale = (document.body.dataset?.locale || 'nl_NL').replace('_', '-');

export const availableLocales: AvailableLocale[] = [
    {
        locale: 'nl_NL'.replace('_', '-'),
        name: 'Nederlands'
    },
    {
        locale: 'en_GB'.replace('_', '-'),
        name: 'English'
    },
    {
        locale: 'fr_FR'.replace('_', '-'),
        name: 'Français'
    },
    {
        locale: 'de_DE'.replace('_', '-'),
        name: 'Deutsch'
    }
];

export function displayLocale(locale: string) {
    return availableLocales.find(l => l.locale === locale.replace('_', '-'))?.name || locale
}

const i18n = createI18n({
    globalInjection: true,
    legacy: false,
    locale: appLocale,
    fallbackLocale: 'nl-NL',
    datetimeFormats: loadLocaleDateTimeFormats(),
    numberFormats: loadLocaleNumberFormats(),
    messages: loadLocaleMessages(),
})

export default i18n

import Decimal from "decimal.js-light";

export function IsInteger(event: KeyboardEvent) {
    if (!/\d/.test(event.key)) return event.preventDefault();
}

export function IsNumber(event: KeyboardEvent) {
    if (!/\d/.test(event.key) && !/\./.test(event.key)) return event.preventDefault();
}

export type IOrderStatusCategory = "pending" | "completed" | "credited";

export function OrderStatusCategory(status?: string): IOrderStatusCategory {
    if (!status || status.indexOf('.') < 0) return 'pending';

    const subStatus = status.substr(status.indexOf('.') + 1)

    if (subStatus === 'completed' || subStatus === 'credited') {
        return subStatus
    }

    return 'pending'
}

export function debounce<T extends Function>(cb: T, wait = 20) {
    let h = 0;
    let callable = (...args: any) => {
        window.clearTimeout(h);
        h = window.setTimeout(() => cb(...args), wait);
    };
    return <T>(<any>callable);
}


export function pushGtmEvents(events?: Array<string>) {
    if (events && Array.isArray(events) && window.dataLayer) {
        events.forEach((gtmEvent: any) => {
            window.dataLayer.push({
                'event': gtmEvent
            })
        })
    }
}


export function pushGtmOrder(order: App.Models.CustomerOrder) {
    let marketTotal: Decimal;
    let orderTotal = new Decimal(order.total)

    if (order.type === 'buy') {
        marketTotal = new Decimal(order.fix.market_ask)
    } else if (order.type === 'sell') {
        marketTotal = (new Decimal(order.fix.market_bid)).negated()
    } else {
        return;
    }

    marketTotal = marketTotal.times(order.quantity)
    const orderMargin = orderTotal.minus(marketTotal)
    const productMargin = orderMargin.div(order.quantity)

    const data = {
        'event': 'purchase',
        'ecommerce': {
            'purchase': {
                'actionField': {
                    'id': order.order_number,
                    'revenue': order.total,
                    'tax': '0',
                    'shipping': '0',
                },
                'products': [{
                    'name': order.type + '.' + order.metal.name,
                    'price': (new Decimal(order.total)).div(order.quantity).toFixed(6, Decimal.ROUND_HALF_UP),
                    'variant': 'regular', // TODO sale
                    'quantity': (new Decimal(order.quantity)).toFixed(0),
                }]
            }
        }
    }

    if (window.dataLayer) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push(data);
    } else {
        console.log('window.dataLayer.push', data);
    }

}

export function formattedDate(date:string) : string {
    return new Date(new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60 * 1000).toLocaleDateString();
}

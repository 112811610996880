
import "./bootstrap"
import "../css/app.css"

// Import modules...
import {createApp, h} from "vue";
import {createInertiaApp} from "@inertiajs/inertia-vue3";
import axios, {AxiosResponse} from "axios";
import { createPinia } from 'pinia'
import { InertiaProgress } from '@inertiajs/progress'
import I18n from "@/Libs/I18n"
import route from "ziggy-js"
import { Chart, LineController, Filler, TimeScale, LinearScale, PointElement, LineElement, Tooltip } from 'chart.js';
import 'chartjs-adapter-luxon';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import {pushGtmEvents} from "@/Libs/utils";
import { ResizeObserver as Polyfill } from '@juggle/resize-observer';

// Polyfill for chartjs on older safari browsers
window.ResizeObserver = window.ResizeObserver || Polyfill;

Chart.register(LineController, Filler, TimeScale, LinearScale, PointElement, LineElement, Tooltip);

const el = document.getElementById('app');

function resolvePageComponent(name: string, pages: Record<string, any>) {
    const path = `./Pages/${name}.vue`
    if (pages.hasOwnProperty(path)) {
        return typeof pages[path] === 'function'
            ? pages[path]()
            : pages[path]
    }

    throw new Error(`Page not found: ${name}`)
}

const pinia = createPinia()

const app = createInertiaApp({
    resolve: (name) => resolvePageComponent(name, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, app, props, plugin }) {
        const vueApp = createApp({ render: () => h(app, props) })
            .mixin({methods: {route}})
            .use(I18n)
            .use(pinia)
            .use(plugin)
            .mount(el)


        if (import.meta.env.PROD) {
            Sentry.init({
                // @ts-ignore
                vueApp,
                dsn: "https://71550642f0744d2aaf58a5a033da68e2@o1008615.ingest.sentry.io/5972593",
                integrations: [
                    new Integrations.BrowserTracing({
                        tracingOrigins: ["account.doijerkalff.nl"],
                    }),
                ],
                tracesSampleRate: 0.2,
            });
        }
    },
})


InertiaProgress.init({ color: '#4B5563' });

document.addEventListener('inertia:navigate', (event: any) => {
    const pageProps = event.detail?.page?.props;

    if (!pageProps) return;

    pushGtmEvents(pageProps?.jetstream?.flash?.gtm_events || null)

    if (!!pageProps?.locale) {
        I18n.global.locale.value = pageProps.locale.replace('_', '-')
    }
})
